<div class="container-fluid">
  <div class="d-flex tab-switcher">
    <button [ngClass]="[!isQuotation ? 'btn cstm-btn-primary': 'cstm-btn-link',(boxShadowFlag&& (!isQuotation))? 'visible-shadow': 'not-visible-shadow']"
      (click)="toggleQuotation(salesOrders)">
      {{"orderHistoryPage.salesOrders" |cxTranslate}}
    </button>
    <button [ngClass]="[isQuotation ? 'btn cstm-btn-primary' : 'cstm-btn-link',(isQuotation&&boxShadowFlag)? 'visible-shadow': 'not-visible-shadow']"
      (click)="toggleQuotation(quotations)">
      {{"orderHistoryPage.quotations" |cxTranslate}}
    </button>
  </div>
  <div class="custom-pagination-position">
    <p-paginator 
      styleClass="cstm-pagination"
      [first]="first"
      [rows]="rows"
      [totalRecords]="totalRecords"
      (onPageChange)="onPageChange($event)">
    </p-paginator>
  </div>
  <app-table-template
    [columns]="columns"
    [allowPagination]="false"
    [apiFilterCall]="true"
    [tableData]="orderHistoryList | async"
    (filteredData)="onFilter($event)"
    (customFilter)="filterOrderHistory($event)"
    (navigateClickEvent)="handleNavigateClick($event)"
  ></app-table-template>
  <div class="custom-pagination-position mt-2">
    <p-paginator 
      styleClass="cstm-pagination"
      [first]="first"
      [rows]="rows"
      [totalRecords]="totalRecords"
      (onPageChange)="onPageChange($event)">
    </p-paginator>
  </div>
</div>