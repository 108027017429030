import { Component } from '@angular/core';
import { Column, ColumnType } from '../../../../SGRE-shared/models/tableConfiguration';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Observable, Subject, map, takeUntil, tap } from 'rxjs';
import { Router } from '@angular/router';
import { OrderDetailsService } from '../../../order-details/services/orderDetails.service';

@Component({
  selector: 'app-order-history-table',
  templateUrl: './order-history-table.component.html',
  styleUrl: './order-history-table.component.scss'
})
export class OrderHistoryTableComponent {

  public columnType: any[];
  public columns: Column[];
  public orderHistoryList: Observable<any>;
  public orderHistoryListData: any[];
  public legalEntityList: any[];
  public legalEntityListUid: any[];
  public isQuotation = false;
  public salesOrders = 'Sales Orders';
  public quotations = 'Quotations';
  public queryFromSearch = ':date-desc';
  public flagFromSearch = false;
  public filterCompanyList: any[] = [];
  public filterLegalEntities: any[] = [];
  public filterRequestedBy: any[] = [];
  public filterApprovedBy: any[] = [];
  public filterOrderStatus: any[] = [];
  public filterQuotation: any[] = [];
  public quotationQuery = ':isQuotation:false';
  filteredTableData: any[] = [];
  private unsubscribe$ = new Subject<void>();
  public first = 0;
  public rows = 20;
  public totalRecords;
  public legalEntities;
  public filterQuery: string = '';
  public boxShadowFlag: boolean = false;

  constructor(
    private storageService: StorageService,
    private globalService: GlobalService,
    public orderService: OrderDetailsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.legalEntities = this.storageService.userLegalEntities
      ? this.storageService.userLegalEntities
      : [];
    this.orderService.removeIsQuotation(this.isQuotation);
    /* From OrderHistorySearchBox Observables */
    this.globalService.queryOrder$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data?.query) {
          this.queryFromSearch = data?.query;
          this.flagFromSearch = data?.flag;
          this.getOrderHistoryList(data?.flag);
        }
      });

    this.globalService.pagination$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.first = 0;
      })

    this.orderHistoryList = this.globalService.ordersList$
      .pipe(
        map(data => {
          this.globalService.filteredData = data?.results;
          this.orderHistoryListData = data?.results;
          this.totalRecords = data?.pagination?.totalResults

          /* Filter Data is taken from table results */
          this.filterCompanyList = data?.facets?.filter(value => value.name === 'company')?.at(0)?.values?.map((value) => ({ company: value?.name, selected: value?.selected }));
          this.filterLegalEntities = data?.facets?.filter(value => value.name === 'legalEntity')?.at(0)?.values?.map((value) => ({ legalEntity: this.findLegalEntityName(value?.name), selected: value?.selected }));
          this.filterRequestedBy = data?.facets?.filter(value => value.name === 'requestedBy')?.at(0)?.values?.map((value) => ({ requestedBy: value?.name?.split('|')?.at(1), selected: value?.selected, value: value?.name }));
          this.filterApprovedBy = data?.facets?.filter(value => value.name === 'approver')?.at(0)?.values?.map((value) => ({ approvedBy: value?.name?.split('|')?.at(1), selected: value?.selected, value: value?.name }));
          this.filterOrderStatus = data?.facets?.filter(value => value.name === 'status')?.at(0)?.values?.map((value) => ({ orderStatus: this.mapOrderStatus(value.name), selected: value?.selected }));
          data?.facets?.forEach(item => {
            if (item.name === 'isQuotation') {
              this.filterQuotation = item?.values?.map(value => value.query.query.value);
            }
          });

          if (this.isQuotation) {
            this.createTableConfigurationWithQuotation();
          } else {
            this.createTableConfiguration();
          }

          return data?.results;
        })
      );
  }

  findLegalEntityName(uid) {
    return this.legalEntities?.find(item => item.uid === uid)?.name;
  }

  findLegalEntityUid(name) {
    return this.legalEntities?.find(item => item.name === name).uid;
  }

  filterOrderHistory(data) {
    let query = '';
    for (let control of Object.keys(data)) {
      let columnName = control;
      if (columnName === 'approvedBy') {
        columnName = 'approver';
      } else if (columnName === 'orderStatus') {
        columnName = 'status';
      }
      data[control]?.forEach(item => {
        if (columnName == 'status') {
          query += `:${columnName}:` + `"${this.getOrderStatus(item)}"`
        } else if (columnName == 'approver') {
          query += `:${columnName}:` + `"${this.getApproverValue(item)}"`;
        } else if (columnName == 'requestedBy') {
          query += `:${columnName}:` + `"${this.getRequestedValue(item)}"`;
        } else if (columnName == 'legalEntity') {
          query += `:${columnName}:` + `"${this.findLegalEntityUid(item)}"`;
        } else {
          query += `:${columnName}:` + `"${item}"`;
        }
      })
    }
    this.filterQuery = query;
    this.getOrderHistoryList();
  }

  getApproverValue(data) {
    return this.filterApprovedBy.filter(item => item['approvedBy']?.includes(data))?.at(0)?.value;
  }

  getRequestedValue(data) {
    return this.filterRequestedBy.filter(item => item['requestedBy']?.includes(data))?.at(0)?.value;
  }

  getOrderStatus(status) {
    if (status == 'Not yet processed') return 'A';
    else if (status == 'Partially processed') return 'B';
    else if (status == 'Completely processed') return 'C';
    else if (status == 'Pending approval') return 'PENDING_APPROVAL';
    else if (status == 'Cancelled') return 'CANCELLED';
    else if (status == 'Rejected') return 'REJECTED';
    else if (status == 'Cancelling') return 'CANCELLING';
    else if (status == 'Approved') return 'APPROVED';
    return status;
  }

  mapOrderStatus(status) {
    if (status == 'A') return 'Not yet processed';
    else if (status === 'B') return 'Partially processed';
    else if (status === 'C') return 'Completely processed';
    else if (status === 'PENDING_APPROVAL') return 'Pending approval';
    else if (status === 'CANCELLED') return 'Cancelled';
    else if (status === 'REJECTED') return 'Rejected';
    else if (status === 'CANCELLING') return 'Cancelling';
    else if (status === 'APPROVED') return 'Approved';
    return status;
  }

  getOrderHistoryList(flag = false) {
    this.globalService.loadingSubject.next(true);
    const requestObject = {
      currentPage: 0,
      pageSize: this.rows,
      fields: 'FULL',
      query: this.queryFromSearch + this.quotationQuery + this.filterQuery
    };
    this.globalService.paginationReset.next(true);
    this.globalService.getOrderHistoryList(requestObject, flag);
  }

  onFilter(data) {
    this.filteredTableData = data;
    this.globalService.filteredData = data.filteredData;
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    const requestObject = {
      currentPage: event.page,
      pageSize: this.rows,
      fields: 'FULL',
      query: this.queryFromSearch + this.quotationQuery + this.filterQuery
    };
    this.globalService.loadingSubject.next(true);
    this.globalService.getOrderHistoryList(requestObject);
  }

  toggleQuotation(quotations) {
    if (quotations === this.salesOrders) {
      this.orderService.removeIsQuotation(this.isQuotation);
      this.isQuotation = false;
      this.quotationQuery = ':isQuotation:false';
      this.createTableConfiguration();
    } else {
      this.isQuotation = true;
      this.quotationQuery = ':isQuotation:true';
      this.createTableConfigurationWithQuotation();
      this.orderService.setIsQuotation(this.isQuotation);
    }
    this.globalService.loadingSubject.next(true);
    const requestObject = {
      currentPage: 0,
      pageSize: 20,
      fields: 'FULL',
      query: this.queryFromSearch + this.quotationQuery + this.filterQuery
    }
    this.boxShadowFlag = true
    setTimeout(() => {
      this.boxShadowFlag = false
    }, 15)
    this.globalService.paginationReset.next(true);
    this.globalService.getOrderHistoryList(requestObject, this.flagFromSearch);
  }

  createTableConfiguration() {
    let columnData = [
      { label: "Company", name: "company", type: ColumnType.text, filter: true, filterOptions: this.filterCompanyList },
      { label: "Legal Entity", name: "legalEntity", type: ColumnType.text, filter: true, filterOptions: this.filterLegalEntities },
      { label: "SGRE SO", name: "sgreSo", type: ColumnType.text },
      { label: "Company PO", name: "companyPo", type: ColumnType.text },
      { label: "Requested By", name: "requestedBy", type: ColumnType.text, filter: true, filterOptions: this.filterRequestedBy },
      { label: "Approved By", name: "approvedBy", type: ColumnType.text, filter: true, filterOptions: this.filterApprovedBy },
      { label: "Order Date", name: "orderDate", type: ColumnType.date },
      { label: "Order Total", name: "orderTotal", type: ColumnType.price, align: 'left' },
      { label: "Order Status", name: "orderStatus", type: ColumnType.text, filter: true, filterOptions: this.filterOrderStatus },
      { label: "Details", name: "details", type: ColumnType.navigate }
    ];
    this.columns = columnData.map(item => new Column(item));
  }

  createTableConfigurationWithQuotation() {
    let columnData = [
      { label: "Company", name: "company", type: ColumnType.text, filter: true, filterOptions: this.filterCompanyList },
      { label: "Legal Entity", name: "legalEntity", type: ColumnType.text, filter: true, filterOptions: this.filterLegalEntities },
      { label: "SGRE SO", name: "sgreSo", type: ColumnType.text },
      { label: "Company PO", name: "companyPo", type: ColumnType.text },
      { label: "Requested By", name: "requestedBy", type: ColumnType.text, filter: true, filterOptions: this.filterRequestedBy },
      { label: "Approved By", name: "approvedBy", type: ColumnType.text, filter: true, filterOptions: this.filterApprovedBy },
      { label: "Requested Date", name: "requestedDate", type: ColumnType.date },
      { label: "Details", name: "details", type: ColumnType.navigate }
    ];
    this.columns = columnData.map(item => new Column(item));
  }

  handleNavigateClick(orderId) {
    let object = this.orderHistoryListData.find(item => item.orderId === orderId);
    let legalEntity = object?.legalEntity;
    let legalEntityNames = this.legalEntities ? this.legalEntities.map(item => item.name) : [];
    this.globalService.updateLegalEntity(legalEntityNames.indexOf(legalEntity));
    this.router.navigate(['order-details'], { queryParams: { orderId: orderId } });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}